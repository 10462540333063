import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import ServiceList from "../elements/service/ServiceList";
/* import Portfolio from '../component/HomeLayout/homeOne/Portfolio';
import CounterOne from '../elements/counters/CounterOne';
import Testimonial from '../elements/Testimonial'; */
import Academia from './Academia';

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Bienvenidos',
        description: '',
        buttonText: 'Contáctanos',
        buttonLink: '#contact'
    }
]
const PortfolioLanding = () => {
    let title = 'Sobre Leomont',
        description = `En Leomont, nos especializamos en utilizar herramientas actuales y sólidas, como React, .Net/C#, SQL Server, para traer tus ideas a la vida. 
        Contamos con la experiencia y habilidades para trabajar con pequeñas startups y grandes empresas. `,
        description1 = `Nuestro equipo no sólo esta dedicado a proveer consultoria y servicios de desarrollo, 
        tambien queremos compartir nuestro conocimiento y experiencia con interesados en adentrarse al mundo de la programación o en aprender nuevas herramientas. 
        Queremos ayudarte a traer tu visión a la realidad.`

    return (
        <div className="active-dark">
            <Helmet pageTitle="Inicio" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">En Leomont somos <br />
                                                <TextLoop>
                                                    <span> Desarrolladores Frontend.</span>
                                                    <span> Desarrolladores Backend.</span>
                                                    <span> Consultores.</span>
                                                    <span> Creadores de Contenido.</span>
                                                </TextLoop>{" "}
                                            </h1>
                                            <h2>en Latam.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about.png" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description1}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Nuestros servicios</h2>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Portfolio Area */}
            <div id="academia" className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55">
                    <Academia />
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start CounterUp Area */}
            {/* <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h3 className="fontWeight500">Our Fun Facts</h3>
                            </div>
                        </div>
                    </div>
                    <CounterOne />
                </div>
            </div> */}
            {/* End CounterUp Area */}

            {/* Start Testimonial Area */}
            {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                <div className="container">
                    <Testimonial />
                </div>
            </div> */}
            {/* End Testimonial Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/discord.png" contactTitle="Contactanos." />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
