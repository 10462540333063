import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import './style.scss'
import PortfolioList from '../data/cursos.json'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import bodyhtml from './body'
import Swal from "sweetalert2"

const Academia = () => {
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState(null)
    const [correoUsuario, setCorreoUsuario] = useState(null)
    const handleClose = () => setShow(false)
    //sendgrid.setApiKey(`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`)

    const handleModalShow = (value) => {
        setShow(true)
        setSelected(value)
    }

    const validateEmail = (email) => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return regex.test(email)
    }

    const handleInscripcion = () => {
        if (validateEmail(correoUsuario)) {
            var formdata = new FormData();
            //EMAIL_COSTO_CURSO 
            //EMAIL_NOMBRE_CURSO programación inicial +web
            formdata.append("bodyhtml", bodyhtml
                .replace('EMAIL_COSTO_CURSO', selected.body)
                .replace('EMAIL_NOMBRE_CURSO', selected.title)
            );
            formdata.append("to", correoUsuario);
            formdata.append("from", "info@leomontdev.com");
            formdata.append("subject", "Información de curso");
            formdata.append("encodingtype", "Base64");
            formdata.append("apikey", "8B18D4428491FAB2E2231F0ED0551426DFE85BC1270C477C2D805CB24426825574C907CCD1D85D1691857FD9BCFCC974");

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://api.elasticemail.com/v2/email/send", requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log({ result })
                    Swal.fire({
                        title: "¡Enviado!",
                        text: "Tu solicitud ha sido recibida, en unos minutos recibirás un correo electrónico para finalizar el proceso de inscripción, en caso de no recibirlo revisa tu bandeja de spam o correo no deseado, o contáctanos via WhatsApp al +56 9 2374 1387",
                        icon: "success"
                    })
                    setShow(false)
                    setSelected(null)
                })
                .catch(error => console.log('error', error));
        }
    }



    let title = 'Academia Leomont',
        description = 'En nuestra academia ofrecemos cursos prácticos con modalidad clase interactiva en línea.'


    return (
        <React.Fragment>
            <div className="portfolio-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>{title}</h2>
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Portfolio Area  */}
                <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
                    <div className="container plr--10">
                        <div className="row row--5">
                            {PortfolioList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="portfolio-style--3">
                                        <div className="thumbnail">
                                            <a>
                                                <img className="w-100" src={`/assets/images/portfolio/portfolio-${value.id}.jpg`} alt="Portfolio Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="portfoliotype">{value.category}</p>
                                            <h4 className="title portfoliotitle"><a>{value.title}</a></h4>
                                            <div className="portfolio-btn">
                                                <button
                                                    onClick={() => handleModalShow(value)}
                                                    className="rn-btn"
                                                    style={{ backgroundColor: '#101010' }}
                                                    disabled={value.status === 0}
                                                >
                                                    {value.status === 1 ? `Inscripciones abiertas!` : `Proximamente`}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
            </div>
            {!selected ? null :
                <Modal show={show} onHide={handleClose} size="xl" className="dark-modal" >
                    <Modal.Header closeButton>
                        <Modal.Title>{`Curso: ${selected.title || 'f'}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-lg-4">
                                <div style={{ maxHeight: 200, overflowY: 'hidden', width: '100%', verticalAlign: 'middle', alignContent: 'center' }}>
                                    <img
                                        src={`/assets/images/portfolio/portfolio-${selected.id}.jpg`}
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </div>
                                <div style={{ marginTop: 20, padding: 20, textAlign: 'center' }}>
                                    <p>{`Inicio: ${selected.startDate}`}</p>
                                    <p>{`Dias: ${selected.courseDay}`}</p>
                                    <p>{`Hora: ${selected.courseHour} (Hora Chile)`}</p>
                                    <p>{`Duracion: ${selected.duration}`}</p>
                                    <p>
                                        {`Costo: ${selected.cost}`}
                                        <br /> (10% dcto en pago total)
                                        <br /> (Facilidades de pago)
                                    </p>
                                </div>
                                <br />
                                <div className="rn-form-group">
                                    <label>Ingrese su correo electronico</label>
                                    <input
                                        type="email"
                                        name="correoUsuario"
                                        placeholder="Ingrese su email"
                                        value={correoUsuario}
                                        required
                                        onChange={(e) => setCorreoUsuario(e.target.value)}
                                    />
                                </div>
                                <GoogleReCaptchaProvider reCaptchaKey="6LeHkB8nAAAAALuCvGrdKzql_FeoxSKU5anLEwVK">
                                    <GoogleReCaptcha onVerify={(e) => console.log({ e })} />
                                </GoogleReCaptchaProvider>

                                <button
                                    onClick={handleInscripcion}
                                    className="rn-btn"
                                    style={{ backgroundColor: '#101010', width: '100%', color: '#fff' }}
                                    disabled={correoUsuario === ""}
                                >
                                    Inscribirme
                                </button>
                                <label>Al presionar el boton, autorizo a Leomont SPA a enviarme informacion sobre el curso a mi correo electronico, el cual proporcione por mi propia cuenta.</label>
                            </div>
                            <div className="col-lg-8" style={{ height: '700px', overflowY: 'scroll' }}>
                                <h3 style={{ color: '#fff' }}>{selected.title}</h3>
                                <h5 style={{ color: '#fff' }}>Contenido:</h5>
                                <ol>
                                    {selected.content.map((modulo) =>
                                        <li>
                                            {modulo.title}
                                            {modulo.children.length <= 0
                                                ? null
                                                : <ul>{modulo.children.map((clase) => <li>{clase.title}</li>)}</ul>}
                                        </li>
                                    )}
                                </ol>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </React.Fragment>
    )
}
export default Academia